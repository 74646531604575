// just a temporary overridden data


const data = {
    de: {
        links: {
            'Indien und Sri Lanka': 'https://kataloge.tourasia.ch/indien-und-sri-lanka-vom-spezialisten/68778888/',
            'Asien': 'https://kataloge.tourasia.ch/asien-vom-spezialisten/68446803/',
            'China und Japan': 'https://kataloge.tourasia.ch/china-und-japan-vom-spezialisten/66053192/'
        }
    },
    fr: {
        links: {
            'Inde et Sri Lanka': 'https://kataloge.tourasia.ch/inde-et-sri-lanka-par-les-specialistes/68778892/',
            'Asie': 'https://kataloge.tourasia.ch/asie-par-les-specialostes/68446810/',
            'Chine et Japon': 'https://kataloge.tourasia.ch/china-et-japon-par-les-specialistes/66053225/'
        }
    }
}

module.exports = data
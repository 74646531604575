import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Grid, Header, Item, Segment, List } from 'semantic-ui-react'
import ReCAPTCHA from 'react-google-recaptcha'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import 'firebase/functions'

import Modal from '../components/Modals/Modal'
import { modalActions } from '../components/Modals/state'
import Layout from '../components/Layouts/PageLayout'
import PageHeader from '../components/Items/PageHeader'
import { getFluidImage, getRawImage } from '../utils/images'
import BrochuresLinks from '../components/Data/BrochuresLinks'

const catalogModalId = 'catalog-modal'

class Catalog extends Component {
  state = {
    firstName: '',
    lastName: '',
    company: '',
    address: '',
    zip: '',
    city: '',
    email: '',
    phone: '',
    remarks: '',
    catalogues: {},
    recaptcha: false,
    errors: {},
  }

  render() {
    const {
      data: {
        allFirestoreBrochures: { edges },
        firestoreImage: { cloudinary, transformation, title, alt },
        firestoreText,
      },
      t,
      pageContext,
      i18n,
    } = this.props
    const {
      firstName,
      lastName,
      company,
      address,
      zip,
      city,
      email,
      phone,
      remarks,
      recaptcha,
      catalogues,
    } = this.state

    const formUrl =
      i18n.language === 'de'
        ? 'https://info.tourasia.ch/l/952092/2022-01-22/wzk'
        : 'https://info.tourasia.ch/l/952092/2022-01-24/xc9'
    const formIframeHeight = i18n.language === 'de' ? '1160' : '1160'

    const getBrochureLink = (brochure) => {
      return brochure.link || BrochuresLinks[i18n.language].links[brochure.title]
    }

    return (
      <div>
        <Helmet>
          <title>{t('catalog.header') + t('htmlTitleSuffix')}</title>

          <meta name='description' content={t('catalog.formHeader')} />
          <meta name='image' content={getRawImage({ cloudinary })} />

          <meta property='og:title' content={t('catalog.header')} />
          <meta property='og:description' content={t('catalog.formHeader')} />
          <meta property='og:image' content={getRawImage({ cloudinary })} />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content='tourasia' />
          <meta name='twitter:title' content={t('catalog.header')} />
          <meta name='twitter:description' content={t('catalog.formHeader')} />
          <meta name='twitter:image' content={getRawImage({ cloudinary })} />
        </Helmet>

        <Layout pageContext={pageContext}>
          <Modal
            id={catalogModalId}
            // header='Gratis Offerte anfragen summary'
            onConfirm={this.handleFormSubmit}
            okLabel={t('catalog.button.order')}
            cancelLabel={t('catalog.button.cancel')}
            size='tiny'
            disabled={!recaptcha}
          >
            <Grid centered>
              <Grid.Column width={8}>
                <Header sub>{t('catalog.firstName')}</Header>
                {firstName}
              </Grid.Column>

              <Grid.Column width={8}>
                <Header sub>{t('catalog.lastName')}</Header>
                {lastName}
              </Grid.Column>

              {company && (
                <Grid.Column width={8}>
                  <Header sub>{t('catalog.company')}</Header>
                  {company}
                </Grid.Column>
              )}

              <Grid.Column width={8}>
                <Header sub>{t('catalog.address')}</Header>
                {address},<br />
                {zip} {city}
              </Grid.Column>

              <Grid.Column width={8}>
                <Header sub>{t('catalog.email')}</Header>
                {email}
              </Grid.Column>

              {phone && (
                <Grid.Column width={8}>
                  <Header sub>{t('catalog.phone')}</Header>
                  {phone}
                </Grid.Column>
              )}

              {remarks && (
                <Grid.Column width={8}>
                  <Header sub>{t('catalog.remarks')}</Header>
                  {remarks}
                </Grid.Column>
              )}

              <Grid.Column width={8}>
                <Header sub>{t('catalog.header')}</Header>
                {Object.entries(catalogues)
                  .filter((catalogue) => catalogue[1] > 0)
                  .map((catalogue) => (
                    <List.Item key={catalogue[0]}>
                      <List.Content>
                        {catalogue[1]} &times; {catalogue[0]}
                      </List.Content>
                    </List.Item>
                  ))}
              </Grid.Column>

              <Grid.Row>
                <Grid.Column width={10}>
                  <ReCAPTCHA
                    sitekey={process.env.RECAPTCHA_CLIENT_KEY}
                    onChange={() => this.setState({ recaptcha: true })}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal>

          <Img
            fluid={getFluidImage({
              cloudinary,
              options: transformation,
              ar: 2.5,
            })}
            alt={alt || title}
          />

          <PageHeader
            title={t('catalog.header')}
            intro={firestoreText ? firestoreText.content : ''}
          />

          <Segment basic vertical padded='very' className='gradient' inverted>
            <Grid stackable container>
              <Header as='h2' color='red'>
                {t('catalog.viewOnline')}
              </Header>
              <Grid.Row columns={3}>
                {edges.map(({ node }) =>
                  node.brochures
                    // .filter((brochure) => brochure.link)
                    .map((brochure) => (
                      <Grid.Column key={getBrochureLink(brochure)}>
                        <a
                          href={getBrochureLink(brochure)}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <Img
                            fluid={getFluidImage({
                              cloudinary: brochure.image.cloudinary,
                              ar: 0.7,
                            })}
                            alt={brochure.image.alt || brochure.image.title}
                          />
                        </a>
                      </Grid.Column>
                    ))
                )}
              </Grid.Row>

              <Header as='h2' color='red'>
                {t('catalog.formHeader')}
              </Header>
              <Grid.Row>
                <Grid.Column computer={11} tablet={16} mobile={16}>
                  {edges.map(({ node }) => (
                    <Segment basic vertical key={node.title}>
                      {/* <Header as='h3' color='red' content={node.title} /> */}

                      <Item.Group>
                        {node.brochures.map((brochure) => (
                          <Item key={brochure.title}>
                            <Item.Image
                              size='tiny'
                              src={getRawImage({
                                cloudinary: brochure.image.cloudinary,
                              })}
                              alt={brochure.image.alt || brochure.image.title}
                            />

                            <Item.Content>
                              <Item.Header color='red'>
                                {brochure.title}
                              </Item.Header>
                              {/* removed by client request */}
                              {/*<Item.Meta>*/}
                              {/*  <Trans i18nKey='catalog.brochures.remark'>*/}
                              {/*    {brochure.remark}*/}
                              {/*  </Trans>*/}
                              {/*</Item.Meta>*/}
                            </Item.Content>
                          </Item>
                        ))}
                      </Item.Group>
                    </Segment>
                  ))}
                </Grid.Column>

                <Grid.Column computer={5} tablet={16} mobile={16}>
                  <Segment color='red' inverted attached='top'>
                    <Header as='h3' inverted>
                      {t('catalog.formHeader')}
                    </Header>
                  </Segment>

                  <Segment attached>
                    <iframe
                      title='pardot_form'
                      src={formUrl}
                      width='100%'
                      height={formIframeHeight}
                      type='text/html'
                      frameborder='0'
                      allowTransparency='true'
                    />

                    <List divided relaxed>
                      {Object.entries(this.state.catalogues)
                        .filter((catalogue) => catalogue[1] > 0)
                        .map((catalogue) => (
                          <List.Item key={catalogue[0]}>
                            <List.Content>
                              {catalogue[1]} &times; {catalogue[0]}
                            </List.Content>
                          </List.Item>
                        ))}
                    </List>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Layout>
      </div>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal,
}

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(Catalog)
)

export const pageQuery = graphql`
  query catalogueQuery($lang: String!) {
    allFirestoreBrochures(
      filter: { lang: { eq: $lang } }
      sort: { fields: [jsonOrder], order: ASC }
    ) {
      edges {
        node {
          title
          brochures {
            link
            image {
              id
              title
              cloudinary
            }
            title
            remark
          }
          lang
          jsonOrder
        }
      }
    }
    firestoreImage(page: { eq: "brochure" }) {
      cloudinary
      transformation
      title
      alt
    }
    firestoreText(lang: { eq: $lang }, section: { eq: "brochure" }) {
      id
      section
      content
      lang
    }
  }
`
